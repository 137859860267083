<template>
  <div class="content">
    <moreHeader :iconColor="1"></moreHeader>
    <h1>{{ data.title }}</h1>
    <div v-if="data.data">
      <div v-for="(item, i) in data.data" :key="i" class="Security">
        <span>{{ item.title }}</span
        >{{ item.text }}
      </div>
    </div>

    <p v-if="data.text">{{ data.text }}</p>
    <div v-if="data.content">
      <div v-for="(item, i) in data.content" :key="i">
        <div class="cloudTitle">{{ item.title }}</div>
        <p>{{ item.text }}</p>
        <img :src="item.img" alt="" class="command" />
      </div>
    </div>
    <img v-if="data.img" :src="data.img" alt="" />
    <div v-if="data.list">
      <span v-for="(item, i) in data.list" :key="i" class="CloudMonitoring">
        <div>
          <img :src="item.img" alt="" />
          <h4>{{ item.title }}</h4>
          <div>{{ item.text }}</div>
        </div>
      </span>
    </div>
    <div v-if="data.imgdata" class="safetyCity">
      <span v-for="(item, i) in data.imgdata" :key="i">
        <img :src="item.img" alt="" />
        <span>{{ item.text }}</span>
      </span>
    </div>
    <div class="more">更多场景：</div>

    <div v-if="id != 0" class="moreScene" @click="go(0)">云监控</div>
    <div v-if="id != 1" class="moreScene" @click="go(1)">指挥调度</div>
    <div v-if="id != 2" class="moreScene" @click="go(2)">九小场所管理</div>
    <div v-if="id != 3" class="moreScene" @click="go(3)">数据融合与共享</div>
  </div>
</template>

<script>
import moreHeader from "../../../components/header.vue";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      id: null,
      data: {},
      data1: {
        title: "云监控",
        text: "云监控系统主要针对现有视频监控难以覆盖的内部区域和室外公共区域，通过部署支持无线WIFI的萤石、乐橙等摄像机，实现视频监控的无缝接入，实现视频监控的全方位、无死角覆盖。",
        img: require("../../../assets/assets/fbiImg/cloudImg1.png"),
        list: [
          {
            title: "成本低",
            img: require("../../../assets/assets/fbiImg/cloudImg2.png"),
            text: "采用价格较低的萤石和乐橙的设备部署成本低。",
          },
          {
            title: "便于实施",
            img: require("../../../assets/assets/fbiImg/cloudImg3.png"),
            text: "不需要专业的安装人员，安装方便即买即用。",
          },
          {
            title: "覆盖面广",
            img: require("../../../assets/assets/fbiImg/cloudImg4.png"),
            text: "可以将视频监控覆盖直接延伸至公共区域内部。",
          },
          {
            title: "建设周期短",
            img: require("../../../assets/assets/fbiImg/cloudImg5.png"),
            text: "通过统一的无线接入方式，可快速实现海量的视频监控接入。",
          },
          {
            title: "群众接受度高",
            img: require("../../../assets/assets/fbiImg/cloudImg6.png"),
            text: "与群众的安全需求相吻合，便于推广。",
          },
        ],
      },
      data2: {
        title: "指挥调度",
        content: [
          {
            title: "辖区情况“一张图”",
            img: require("../../../assets/assets/fbiImg/commandImg1.png"),
            text: "辖区情况“一张图”展示，警务人员可在警情案件发生的第一时间辖区内的范围内的视频监控点位，提高办案效率。",
          },
          {
            title: "警力分布“一张图”",
            img: require("../../../assets/assets/fbiImg/commandImg2.png"),
            text: "警力分布“一张表”，一旦发生警情事件可直接交办到人，实现灵活的指挥调配。",
          },
          {
            title: "警情分流任务管理",
            img: require("../../../assets/assets/fbiImg/commandImg3.png"),
            text: "快速本辖区不同时间段的案件，并制定解决方案，最大限度的节省时间。",
          },
          {
            title: "多终端案件受理",
            img: require("../../../assets/assets/fbiImg/commandImg4.png"),
            text: "案件的受理、指派、上报等操作可通过多终端实现；警员可及时接收到案件详情，并可调看案发现场监控。",
          },
        ],
      },
      data3: {
        title: "九小场所管理",
        text: "系统汇集了各种基础要素，方便警员可快速查看本辖区的基础要素概况与详细情况，有效提升辖区重点区域防控。",
        img: require("../../../assets/assets/fbiImg/img6.png"),
      },
      data4: {
        title: "数据融合与共享",
        text: "支持多种物联网设备的数据接入，如监控设备、消防设备、电梯设备、闸口设备等；也能直接从政府、企业的各类数据平台拉取数据，所聚合的数据，可以快速共享到其他平台。",
        imgdata: [
          {
            text: "闸口设备",
            img: require("../../../assets/assets/fbiImg/shareImg1.png"),
          },
          {
            text: "监控设备",
            img: require("../../../assets/assets/fbiImg/shareImg2.png"),
          },
          {
            text: "电梯设备",
            img: require("../../../assets/assets/fbiImg/shareImg3.png"),
          },
          {
            text: "井盖设备",
            img: require("../../../assets/assets/fbiImg/shareImg4.png"),
          },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.query.fbiId;
    const i = this.$route.query.fbiId;
    // eslint-disable-next-line eqeqeq
    if (i == 0) {
      this.data = this.data1;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 1) {
      this.data = this.data2;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 2) {
      this.data = this.data3;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 3) {
      this.data = this.data4;
    }
  },
  methods: {
    go(id) {
      this.$router.push({
        path: "/m_fbiDetail",
        query: {
          fbiId: id,
        },
      });
      this.id = id;
      if (id == 0) {
        this.data = this.data1;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 1) {
        this.data = this.data2;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 2) {
        this.data = this.data3;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 3) {
        this.data = this.data4;
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -27px;
  box-sizing: border-box;
  padding-bottom: 10px;
  > h1 {
    padding-left: 10px;
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: #333333;
    padding-top: 70px;
  }
  P {
    padding-left: 10px;
    text-indent: 2em;
    width: calc(100% - 20px);
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 28px;
    color: #666666;
    margin: 20px 0;
  }
  > img {
    padding-left: 10px;
    display: inline-block;
    width: calc(100% - 20px);
    margin-bottom: 10px;
  }
  .more {
    margin: 0 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
    border-top: 1px solid #d5d5d5;
    padding-top: 20px;
  }
  .moreScene {
    padding-left: 20px;
    width: 260px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #365ef1;
    margin-top: 10px;
  }
  .cloudTitle {
    font-size: 16px;
    font-weight: normal;
    color: #3651d8;
    margin: 10px;
  }
  .command {
    width: calc(100% - 20px);
    height: 200px;
    margin-left: 10px;
  }
  .safetyCity {
    margin-top: 18px;
    > span {
      display: inline-block;
      width: calc(50% - 15px);
      margin-left: 10px;
      img {
        display: inline-block;
        width: 100%;
        // margin-left: 20px;
      }
      span {
        width: 100%;
        display: inline-block;
        height: 18px;
        line-height: 18px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        margin: 10px 0;
      }
    }
  }
  .Security {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 300;
    margin-top: 16px;
    color: #666666;
    width: calc(100% - 20px);
    margin-left: 10px;
    > span {
      color: rgb(39, 38, 38);
      font-weight: 400;
    }
  }
  .CloudMonitoring {
    display: inline-block;
    width: 50%;
    height: 190px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 26px;
    > div {
      width: 100%;
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        display: inline-block;
        width: 50px;
      }
      > h4 {
        font-size: 14px;
        padding: 20px 0;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      > div {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #6b6b6b;
      }
    }
  }
}
</style>
